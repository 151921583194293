<template>
  <div class="screen">
    <ul class="ul">
      <li class="li">
        <div class="label mr12">所在地区：</div>
        <div class="label">省份</div>
        <Select v-model="seacrh.provinceId" size="small" clearable @on-change="getcityId">
          <Option
            v-for="item in listData"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
        <div class="label">城市</div>
        <Select
          v-model="seacrh.cityId"
          size="small"
          clearable
          @on-change="getareaId"
          not-found-text="请先选择省份"
        >
          <Option
            v-for="item in cityList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
        <div class="label">区/县</div>
        <Select
          v-model="seacrh.areaId"
          size="small"
          clearable
          not-found-text="请先选择城市"
        >
          <Option
            v-for="item in areaList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </li>
      <li class="li">
        <div class="label">陵园价格：</div>
        <RadioGroup v-model="button6" type="button" size="small">
          <Radio
            v-for="(item, idx) in priceList"
            :label="item.value"
            :value="item.value"
            :key="idx"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
      </li>
    </ul>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  data() {
    return {
      button6: "",
      seacrh: {
        provinceId: null,
        cityId: null,
        areaId: null,
      },
      listData: [],
      // provinceList: [{ name: "全部", id: 0 }],
      cityList: [],
      areaList: [],
      priceList: [
        { name: "全部", value: "", id: 0 },
        { name: "一万以下", value: "<10000", id: 1 },
        { name: "十万以下", value: "<100000", id: 2 },
        { name: "五十万以下", value: "<500000", id: 3 },
        { name: "一百万以下", value: "<1000000", id: 4 },
        { name: "一百万以上", value: ">1000000", id: 5 },
      ],
      featureList: [
        { name: "全部", id: 0 },
        { name: "传统立牌", id: 1 },
        { name: "传统立牌", id: 2 },
        { name: "传统立牌", id: 3 },
        { name: "传统立牌", id: 4 },
        { name: "传统立牌", id: 5 },
        { name: "传统立牌", id: 6 },
        { name: "传统立牌", id: 7 },
      ],
      professionList: [
        { name: "全部", id: 0 },
        { name: "传统立牌", id: 1 },
        { name: "传统立牌", id: 2 },
        { name: "传统立牌", id: 3 },
        { name: "传统立牌", id: 4 },
        { name: "传统立牌", id: 5 },
        { name: "传统立牌", id: 6 },
        { name: "传统立牌", id: 7 },
      ],
    };
  },
  watch: {
    button6: {
      deep: true,
      handler: function (newV, oldV) {
        console.log(newV);
        this.$emit("updateInfo", newV);
        // !newV && this.bth();
      },
    },
    seacrh: {
      deep: true,
      handler: function (newV, oldV) {
        console.log(newV);
        this.$emit("updateInfoAd", newV);
        // !newV && this.bth();
      },
    },
  },
  mounted() {
    this.address();
  },
  methods: {
    address() {
      api.companyLinkage({}, (res) => {
        console.log(res.data);
        this.listData = res.data.linkageData;
      });
    },
    getcityId(item) {
      this.seacrh.cityId = null;
      this.seacrh.areaId = null;
      let arr = this.listData.filter((v) => {
        if (v.value == item) {
          return v;
        }
      });
      this.cityList = arr[0].children || [];
    },
    getareaId(item) {
      this.seacrh.areaId = null;
      let arr = this.cityList.filter((v) => {
        if (v.value == item) {
          return v;
        }
      });
      this.areaList = arr[0].children  || [];
    },
  },
};
</script>

<style lang="less" scoped>
.screen {
  width: 100%;
  box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
  background: #fff;
  .ul {
    .li {
      height: 50px;
      display: flex;
      padding: 0 20px;
      align-items: center;
      border-bottom: 1px solid #ececec;
      line-height: 50px;
      &:nth-child(1) {
        height: 57px;
        line-height: 57px;
      }
      &:nth-last-child(1) {
        height: 57px;
        line-height: 57px;
        border: none;
      }
      .label {
        font-size: 12px;
        font-weight: 400;
        color: #363637;
        margin-right: 4px;
        &.mr12 {
          margin-right: 12px;
        }
      }
    }
  }
  /deep/.ivu-radio-group-item {
    border: none;
    color: #6085bb;
    border-radius: 2px !important;
    font-size: 12px;
    padding: 4px 8px;
    line-height: 17px;
    margin-right: 4px;
    &::before,
    &::after {
      display: none;
    }
    &:focus {
      border: none;
    }
  }
  .ivu-radio-wrapper-checked {
    box-shadow: none;
    background: #299654;
    color: #fff;
  }
  /deep/.ivu-radio-focus {
    box-shadow: none !important;
  }
  /deep/.ivu-select {
    line-height: 25px;
    margin-right: 20px;
    width: 100px;
    height: 25px;
  }
}
</style>