<template>
  <div class="StoreOnline_ly">
    <div class="banner">
      <img src="@/assets/images/parkBanner.png" alt="" />
    </div>
    <!-- 在线商城 -->
    <div v-if="$route.path == '/StoreOnline_ly'">
      <div class="container">
        <div class="title">
          全部商品
          <span @click="routeTo('goodsList_ly',{'companyId':companyId})"
            >全部商品 <i class="iconfont iconarrow-right"></i
          ></span>
        </div>
        <div class="recommendGoods">
          <!-- 378 -->
          <div class="click_left" @click="click_right">
            <i class="iconfont iconarrow-left"></i>
          </div>
          <div class="click_right" @click="click_left">
            <i class="iconfont iconarrow-right"></i>
          </div>
          <div class="recommendGoodsList">
            <ul :style="`transform: translateX(-${285 * clickCount}px);`">
              <li v-for="item in list" @click="goodsDetail({...item,'companyId':companyId})">
                <img :src="item.goodsPic" />
                <div class="desc">
                  <div>{{ item.goodsName }}</div>
                  <div>
                    ¥{{ item.goodsDiscountPrice || item.goodsCommodityPrice }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">推荐商品</div>
        <div class="limitedGoods">
          <ul>
            <li
              v-for="(item, index) in list1"
              :key="index"
              @click="goodsDetail({...item,'companyId':companyId})"
            >
              <img :src="item.goodsPic" />
              <div class="desc">
                <div>{{ item.goodsName }}</div>
                <div>
                  <span>¥{{ item.goodsDisCountPrice }}</span>
                  <span>¥{{ item.goodsCommodityPrice }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="total"
            :page-size="searchForm.pageSize"
            :current.sync="searchForm.page"
            @on-change="search"
            @on-page-size-change="
              (pageSize) => {
                (searchForm.pageSize = pageSize), search();
              }
            "
          />
        </Row>
      </div>
      <div class="container">
        <div class="title">限时抢购</div>
        <div class="limitedGoods">
          <ul>
            <li
              v-for="(item, index) in list2"
              :key="index"
              @click="goodsDetail({...item,'companyId':companyId})"
            >
              <img :src="item.goodsPic" />
              <div class="desc">
                <div>{{ item.goodsName }}</div>
                <div>
                  <span>¥{{ item.goodsDisCountPrice }}</span>
                  <span>¥{{ item.goodsCommodityPrice }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="total2"
            :page-size="searchForm.pageSize"
            :current.sync="searchForm.page"
            @on-change="search"
            @on-page-size-change="
              (pageSize) => {
                (searchForm.pageSize = pageSize), search();
              }
            "
          />
        </Row>
      </div>
    </div>
    <!-- 全部商品 -->
    <div v-else>
      <div class="container">
        <screenOne v-if="$route.path == '/goodsList_ly'" @updateInfo="updateInfo" style="margin-top:-70px;"/>
        <screen v-else></screen>
        <div class="inputStyle"><input v-model="value" class="input" placeholder="请输入商品名称"/><div class="btn" @click="write">搜索</div></div>
        <goods-list :categoryId="categoryId" :companyId="companyId" :goodsName="goodsName"></goods-list>
      </div>
    </div>
  </div>
</template>

<script>
// import goodsList from "../components/goodsList.vue";
import goodsList from "../../StoreOnline/components/goodsList.vue";
import screen from "../components/screen.vue";
import api from "@/util/api";
import screenOne from "../../StoreOnline/components/screen"
export default {
  data() {
    return {
      goodsName:'',
      value:'',
      showAll: false,
      clickCount: 0,
      companyId: this.$route.query.companyId || 0,
      list: [],
      list1: [],
      list2: [],
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      total2: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      categoryId:0,
    };
  },
  components: {
    goodsList,
    screen,
    screenOne
  },
  mounted() {
    
    if (this.$route.path == "/StoreOnline_ly") {
      this.search("推荐商品");
      this.search("限时抢购");
      this.shopAllGoods();
    }
  },
  methods: {
    updateInfo(categoryId){
      this.categoryId = categoryId;
    },
    write(){
      this.goodsName = this.value;
    },
    click_left() {
      if(this.clickCount < this.list.length -4){
        this.clickCount++;
      }
    },
    click_right() {
      this.clickCount--;
      this.clickCount < 0 && (this.clickCount = 0);
    },
    shopAllGoods() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.companyId = this.companyId;
      api.shopAllGoods(searchForm, (res) => {
        this.list = res.resultList;
      });
    },
    search(name) {
      let searchForm = this.searchForm;
      searchForm.search.companyId = this.companyId;
      searchForm.search.arrondiName = name;
      api.taoruShopArrondiGoodsByName(searchForm, (res) => {
        switch (name) {
          case "推荐商品":
            this.list1 = res.resultList;
            this.total = res.resultCount;
            break;
          case "限时抢购":
            this.list2 = res.resultList;
            this.total2 = res.resultCount;
            break;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.StoreOnline_ly {
  .banner {
    height: 220px;
  }
  .title {
    position: relative;
    width: 1120px;
    height: 65px;
    background: #ffffff;
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 65px;
    letter-spacing: 1px;
    margin-top: 18px;
    padding-left: 40px;
    padding-right: 20px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 24px;
      background: #299654;
      border-radius: 2px;
      left: 20px;
      top: 20px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      color: #838383;
      letter-spacing: 1px;
      float: right;
      cursor: pointer;
    }
  }
  .recommendGoods {
    width: calc(100% + 1px);
    position: relative;
    .click_left,
    .click_right {
      position: absolute;
      width: 60px;
      height: 60px;
      background: #ffffff;
      border-radius: 50%;
      font-size: 28px;
      top: 150px;
      color: #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .click_left {
      left: -80px;
    }
    .click_right {
      right: -80px;
    }
    .recommendGoodsList {
      width: 100%;
      overflow: hidden;
      ul {
        transition: all 1s;
        width: 100%;
        display: flex;
        margin-top: 12px;
        li:not(:nth-child(1)){
          margin-left:20px;
        }
        li {
          width: 265px;
          height: 360px;
          background: #ffffff;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          flex-shrink: 0;
          // margin-right: 13px;
          img {
            width: 265px;
            height: 260px;
            border-radius: 2px 2px 0px 0px;
          }
          .desc {
            padding: 14px 24px;
            div:nth-child(1) {
              // width: 178px;
              height: 33px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #299654;
              line-height: 33px;
              letter-spacing: 1px;
            }
            div:nth-child(2) {
              width: 90px;
              height: 33px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e64747;
              line-height: 33px;
              letter-spacing: 1px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .limitedGoods {
    ul {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      margin-bottom: 40px;
      li {
        width: 214px;
        height: 330px;
        background: #ffffff;
        border-radius: 2px;
        margin-right: 12px;
        margin-bottom: 12px;
        &:nth-child(5n) {
          margin-right: 0;
        }
        img {
          width: 214px;
          height: 214px;
        }
        .desc {
          padding: 12px 16px 20px;
          div:nth-child(1) {
            width: 182px;
            height: 48px;
            font-size: 16px;
            font-weight: 400;
            color: #838383;
            line-height: 24px;
            letter-spacing: 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          div:nth-child(2) {
            width: 182px;
            height: 24px;
            line-height: 22px;
            background: #ffffff;
            border: 1px solid #299654;
            margin-top: 12px;
            display: flex;
            span {
              flex: 1;
              text-align: center;
              font-size: 12px;
              &:nth-child(1) {
                color: #fff;
                background: #299654;
              }
              &:nth-child(2) {
                color: #929292;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
  .screen {
    margin-top: -107px;
    position: relative;
  }
  .inputStyle{
    display: flex;
    justify-content: flex-end;
    margin-top:40px;
    .input{
      height: 30px;
      width:300px;
      border: 2px solid #299654;
      text-indent:10px;
      cursor: pointer;
    }
    .btn{
      cursor: pointer;
      width: 60px;
      height: 30px;
      line-height:32px;
      text-align: center;
      background: #299654;
      color:white;
    }
  }
}
</style>