<template>
  <div class="screen">
    <ul class="ul">
      <li class="li">
        <div class="label">商品类型：</div>
        <RadioGroup v-model="categoryId" type="button" size="small">
          <Radio v-for="(item, idx) in arrList" :label="item.categoryId" :key="idx">{{
            item.categoryName
          }}</Radio>
        </RadioGroup>
      </li>
    </ul>
  </div>
</template>

<script>
import api from "@/util/api";
import searchVue from "../../Index/search.vue";
export default {
  data() {
    return {
      categoryId:0,
      seacrh: {
        provinceId: 0,
        cityId: 0,
        areaId: 0,
      },
      arrList:[],
      provinceList: [{ name: "全部", id: 0 }],
      cityList: [{ name: "全部", id: 0 }],
      areaList: [{ name: "全部", id: 0 }],
      priceList: [
        { name: "全部", id: 0 },
        { name: "一万以上", id: 1 },
        { name: "一万以下", id: 2 },
        { name: "一万以下", id: 3 },
        { name: "一万以下", id: 4 },
        { name: "一万以下", id: 5 },
        { name: "一万以下", id: 6 },
        { name: "一万以下", id: 7 },
      ],
    };
  },
    watch:{
    categoryId: {
      deep: true,
      handler: function (newV, oldV) {
        this.$emit("updateInfo",newV);
        // !newV && this.bth();
      },
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      api.taoruShopGoodsCategory({}, (res) => {
        let arr = [];
        arr.push({categoryId: '',categoryName: "全部"})
        for(let i in res.data){
            res.data[i].childList.map(v=>{
              arr.push(v);
            })
        }
        this.arrList=arr;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.screen {
  width: 100%;
  box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
  background: #fff;
  .ul {
    .li {
      height: 50px;
      display: flex;
      padding: 0 20px;
      align-items: center;
      border-bottom: 1px solid #ececec;
      line-height: 50px;
      &:nth-child(1) {
        height: 57px;
        line-height: 57px;
      }
      &:nth-last-child(1) {
        height: 57px;
        line-height: 57px;
        border: none;
      }
      .label {
        font-size: 12px;
        font-weight: 400;
        color: #363637;
        margin-right: 4px;
        &.mr12 {
          margin-right: 12px;
        }
      }
    }
  }
  /deep/.ivu-radio-group-item {
    border: none;
    color: #6085bb;
    border-radius: 2px !important;
    font-size: 12px;
    padding: 4px 8px;
    line-height: 17px;
    margin-right: 4px;
    &::before,
    &::after {
      display: none;
    }
    &:focus {
      border: none;
    }
  }
  .ivu-radio-wrapper-checked {
    box-shadow: none;
    background: #299654;
    color: #fff;
  }
  /deep/.ivu-radio-focus {
    box-shadow: none !important;
  }
  /deep/.ivu-select {
    line-height: 25px;
    margin-right: 20px;
    width: 100px;
    height: 25px;
  }
}
</style>